@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
}
